import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {parse} from "dotenv";
import {toast} from "react-toastify";

class Convert extends Component {
    constructor() {
        super();
        this.state={
            wallet:[],token:"",amount:"0",total:"0",loading:false,
            fromid:"9",fromlogo:"K4MEZfSsQg-aow.png",fromname:"BNB",frombal:"0",fromprice:"0",
            toid:"14",tologo:"qKGtCMx_qJZv3g.png",toname:"KOCO",tobal:"0",toprice:"0"
        }
    }
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var token1 = query.get('token')
        if(token1){
            this.setState({token:token1})
            console.log(token1)
            localStorage.setItem("authtoken",token1)
            setTimeout(()=>{
                this.getWallet()
            },200)
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            this.setState({token:token,uid:decoded.uid})
            setTimeout(()=>{
                this.getWallet()
            },200)
        }else {
            this.props.history.push("/login")
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata,loading:false})
            walletdata.filter(res=>{
                if(res.coin_symbol==this.state.fromname){
                    this.setState({fromid:res.coin_id,
                        fromlogo:res.logo_img,
                        fromname:res.coin_symbol,
                        frombal:res.balance,fromprice:res.price})
                }
            })
            walletdata.filter(res=>{
                if(res.coin_symbol==this.state.toname){
                    this.setState({toid:res.coin_id,
                        tologo:res.logo_img,
                        toname:res.coin_symbol,
                        tobal:res.balance,toprice:res.price})
                }
            })
        }
    }

    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                console.log(res.data.data)
                if (res.data.success){
                    if(res.data.data.length>0){
                        this.setState({wallet:res.data.data})
                        localStorage.setItem("wallet",JSON.stringify(res.data.data))
                        res.data.data.filter(res=>{
                            if(res.coin_symbol==this.state.fromname){
                                this.setState({fromid:res.coin_id,
                                    fromlogo:res.logo_img,
                                    fromname:res.coin_symbol,
                                    frombal:res.balance,fromprice:res.price})
                            }
                        })
                        res.data.data.filter(res=>{
                            if(res.coin_symbol==this.state.toname){
                                this.setState({toid:res.coin_id,
                                    tologo:res.logo_img,
                                    toname:res.coin_symbol,
                                    tobal:res.balance,toprice:res.price})
                            }
                        })
                    }
                }
            })
            .catch(err=>{
                this.getWallet()
            })

    }


    fromc=(e)=>{
        this.state.wallet.filter(res=>{
            if(res.coin_symbol==e.target.value){
                this.setState({fromid:res.coin_id,
                    fromlogo:res.logo_img,
                    fromname:res.coin_symbol,amount:"0",total:"0",
                    frombal:res.balance,fromprice:res.price})
            }
        })
    }
    toc=(e)=>{
        this.state.wallet.filter(res=>{
            if(res.coin_symbol==e.target.value){
                this.setState({toid:res.coin_id,
                    tologo:res.logo_img,
                    toname:res.coin_symbol,
                    tobal:res.balance,toprice:res.price})
            }
        })
    }

    movech=()=>{
        var val=this.state
        setTimeout(()=>{
            this.setState({
                toid:val.fromid,
                tologo:val.fromlogo,
                toname:val.fromname,
                tobal:val.frombal,toprice:val.fromprice})

            this.setState({
                fromid:val.toid,
                fromlogo:val.tologo,
                fromname:val.toname,
                frombal:val.tobal,fromprice:val.toprice})
        },40)
        setTimeout(()=>{
            this.calamount()
        },50)
    }
    maxb=()=>{
        this.setState({amount:this.state.frombal})
        setTimeout(()=>{
            this.calamount()
        },50)
    }
    amount=(e)=>{
      this.setState({amount:e.target.value})
        setTimeout(()=>{
            this.calamount()
        },50)
    }
    total=(e)=>{
        this.setState({total:e.target.value})
        var val = this.state
        setTimeout(()=>{
            var rate = (parseFloat(val.fromprice)/parseFloat(val.toprice)*1)
            var amount = (parseFloat(e.target.value)/rate)
            console.log("Rate : ",rate)
            console.log("Amount : ",amount)
            this.setState({amount:amount.toFixed(4)})
        },50)
    }
    calamount=()=>{
       var val = this.state
       var rate = (parseFloat(val.fromprice)/parseFloat(val.toprice)*parseFloat(val.amount)).toFixed(4)
        console.log(rate)
        console.log("Amount : ",val.amount)
        console.log("From Price : ",val.fromprice)
        console.log("To Price : ",val.toprice)
        this.setState({total:rate})
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }


    convertSubmit=()=>{
        var val = this.state
        var amount = val.amount
        var bal = val.frombal
        if(val.amount=="" || parseFloat(val.amount)==0){
            this.errorMsg("Amount is required")
        }

        if(parseFloat(amount)>parseFloat(bal)){
            this.errorMsg("Balance not enough")
        }
        if(parseFloat(amount)<=parseFloat(bal) && val.amount!=="" && parseFloat(val.amount)!==0 ){
            this.setState({loading:true})
            var formd = new FormData()
            formd.append("token",val.token)
            formd.append("fromid",val.fromid)
            formd.append("toid",val.toid)
            formd.append("amount",val.amount)
            Axios.post(ApiUrl.baseurl+"convert",formd)
                .then(res=>{
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        this.getWallet()
                    }else {
                        this.errorMsg(res.data.error)
                    }
                    this.setState({loading:false})
                })
                .catch(err=>{
                    //console.log(err)
                    this.setState({loading:false})
                })
        }
    }

    render() {
        var val = this.state
        var fromw = this.state.wallet.filter(res1=>{
            if(res1.coin_symbol!==val.toname){
               return res1
            }
        }).map(res=>{
            return(
                <option
                        selected={res.coin_symbol==val.fromname} value={res.coin_symbol}>{res.coin_symbol}</option>
            )
        })

        var tow = this.state.wallet.filter(res1=>{
            if(res1.coin_symbol!==val.fromname){
                return res1
            }
        }).map(res=>{
            return(
                <option
                        selected={res.coin_symbol==val.toname} value={res.coin_symbol}>{res.coin_symbol}</option>
            )
        })

        return (
            <>
                <TopMenu title={"KOCO Convert"}/>
                <div className="convert">
                    <div className="row m-0">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 p-0">
                            <div className="main">
                                <p>From: <b>{val.fromname}</b>
                                    <span style={{float:"right"}}>
                                    <img src={ApiUrl.photoUrl+val.fromlogo}/>
                                        <select onChange={this.fromc} style={{border:"none",outline:"none",
                                            width:"69px",background:"transparent",textAlign:"center",color:"#008CFF",fontWeight:"bold"}}>
                                            {fromw}
                                        </select>
                                </span>
                                </p>
                                <input type="number" onChange={this.amount} value={val.amount} />
                                <p>Balance: {parseFloat(val.frombal).toFixed(4)} {val.fromname}
                                    <b onClick={this.maxb} style={{color:"#3E6EFF",marginLeft:"10px",cursor:"pointer"}}>MAX</b></p>

                                <p style={{textAlign:"center"}}>
                                    <img onClick={this.movech} style={{height:"35px",width:"40px",cursor:"pointer"}} src="/downb.png"/>
                                </p>

                                <p>To: <b>{val.toname}</b>
                                    <span style={{float:"right"}}>
                                    <img src={ApiUrl.photoUrl+val.tologo}/>
                                        <select onChange={this.toc} style={{border:"none",outline:"none",
                                            width:"69px",background:"transparent",textAlign:"center",color:"#008CFF",fontWeight:"bold"}}>
                                            {tow}
                                        </select>
                                </span>
                                </p>
                                <input type="number" onChange={this.total} value={val.total} />
                                <p>Balance: {parseFloat(val.tobal).toFixed(4)} {val.toname} </p>
                            </div>

                            {val.amount==="" || val.amount==="0" ? "":
                                <p style={{textAlign:"center",marginTop:"25px"}}>
                                    1 {val.fromname} = {(parseFloat(val.fromprice)*1/parseFloat(val.toprice)).toFixed(4)} {val.toname}
                                </p>}
                            <button disabled={val.loading} onClick={this.convertSubmit} style={{marginTop:"40px"}}>
                                {val.loading==true?"Converting...":"Convert"}
                            </button>


                        </div>
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
            </>
        );
    }
}

export default Convert;