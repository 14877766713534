import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import jwtDecode from "jwt-decode";
import {toast} from "react-toastify";
import ApiUrl from "../AppUrl/ApiUrl";
import Axios from "axios";
import moment from "moment";

export function TimeAgo(date){
    var localTime  = moment.utc(date).toDate();
    return localTime
}

class Mining extends Component {
    constructor() {
        super();
        this.state={
            token:"",weburl:"",ref_code:"",balance:"0",coin_name:"",coin_symbol:"",
            logo_img:"",next_mining:"",mining_amount:"0",status:"0",
            d:"0",h:"0",m:"0",s:"0",amount:"",loading:false,loading1:false
        }
        this.interval=null
    }
    componentDidMount() {
        this.setState({weburl:window.location.hostname})
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            //console.log(decoded.user_id)
            var mining = JSON.parse(localStorage.getItem("mining"))
            if(mining){
                var val = mining.data
                //console.log(val)
                this.setState({
                    status:"0",mining_amount:val["mining_amount"],next_mining:val["next_mining"],
                    balance:val["balance"],coin_name:val["coin_name"],coin_symbol:val["coin_symbol"],
                    logo_img:val["logo_img"]})
                var countDownDate = new Date(TimeAgo(val["next_mining"])).getTime();
                var now = new Date().getTime();
                if(countDownDate>now){
                    this.setState({status:"1"})
                }
            }
            this.setState({token:token,ref_code:decoded.user_id})
            setTimeout(()=>{
                this.getMiningStatus()
            },300)
        }else {
            this.props.history.push("/login")
        }
        this.interval = setInterval(()=>{
            this.countDown()
        },1000)
    }
    getMiningStatus=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/miningcheck",formd)
            .then(res=>{
                var val = res.data.data
                //console.log(val)
                if(val.balance){
                    localStorage.setItem("mining",JSON.stringify(res.data))
                    console.log("data found")
                }

                this.setState({
                    status:res.data.status,mining_amount:val["mining_amount"],next_mining:val["next_mining"],
                    balance:val["balance"],coin_name:val["coin_name"],coin_symbol:val["coin_symbol"],
                    logo_img:val["logo_img"]})
            })
            .catch(err=>{
                //
            })
        Axios.post(ApiUrl.baseurl+"all/miningcheck",formd)
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                //
            })
    }

    countDown=()=>{
        var countDownDate = new Date(TimeAgo(this.state.next_mining)).getTime();
        if(this.state.status=="1"){
            var now = new Date().getTime();
            //console.log(TimeAgo(this.state.next_mining))
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({d:days,h:hours,m:minutes,s:seconds})
            if(now>countDownDate){
                this.getMiningStatus()
            }
        }
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    onCopyClipboard=(val)=>{
        this.props.history.push("/my/referral")
    }

    startMining=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        if(this.state.status=="0" || this.state.loading==false){
            this.setState({loading:true})
            Axios.post(ApiUrl.baseurl+"auth/miningstart",formd)
                .then(res=>{
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                    }else {
                        this.successMsg(res.data.success)
                    }
                    this.setState({loading:false})
                    this.getMiningStatus()
                })
                .catch(err=>{
                    this.setState({loading:false})
                })
        }else {
            this.errorMsg("Your mining already running")
        }
    }

    componentWillUnmount(){
        //console.log(this.interval)
        try{
            clearInterval(this.interval)
            this.interval=null
        }catch(err){
            console.log(err)
        }
    }
    gotoWithdrew=(val)=>{
        this.props.history.push(val)
    }
    amount=(e)=>{
        this.setState({amount:e.target.value})
    }
    all=()=>{
        this.setState({amount:this.state.balance})
    }
    transfer=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        formd.append("amount",this.state.amount)
        if(parseFloat(this.state.amount)>0){
            this.setState({loading1:true})
            Axios.post(ApiUrl.baseurl+"auth/transfer",formd)
                .then(res=>{
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                    }else {
                        this.successMsg(res.data.success)
                    }
                    this.setState({loading1:false})
                    this.getMiningStatus()
                })
                .catch(err=>{
                    this.setState({loading1:false})
                })
        }else {
            this.errorMsg("Amount is required")
        }
    }
    render() {
        var val =this.state
        //console.log(val)
        return (
            <>
                <TopMenu title="KocoCoin Mining"/>
                <div className="miningpage">
                    <div style={{padding:"10px 20px", textAlign:"center"}}>
                        <p className="text-danger fw-bold">
                            Mining closing and Transfer Date 5 November,<br/>
                            Please quickly Transfer your Mining amount  to Spot Balance Before 5 November<br/>
                            if you don’t transfer you will lose your Mining Balance
                        </p>
                    </div>
                  <div className="bgimg">
                      <div className="row m-0">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                              <div className="bal">
                                 <h1 className="text-black">Balance</h1>
                                  <p>
                                      <img src={val.logo_img?ApiUrl.photoUrl+val.logo_img:"./icon.png"}/>
                                      {parseFloat(val.balance).toFixed(5)}
                                  </p>
                              </div>
                              <br/>
                              <div className="miningcircle">
                                 <div className="middiv">
                                     <h5>{val.d}d</h5>
                                     <p>{val.s}s</p>
                                     <p>{val.m}m</p>
                                     <p>{val.h}h</p>
                                     <h5>{val.mining_amount} KOCO/d</h5>
                                 </div>
                              </div>
                          </div>
                      </div>
                  </div>
                    <div className="row m-0">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 col-12">
                            <br/>
                            {
                                val.loading==true?
                                    <div
                                        className="claimbtn">Loading...</div>:
                                    <div onClick={this.startMining} className="claimbtn">
                                        {val.status=="0"?"Claim now":"Claimed"}
                                    </div>
                            }

                            <div className="row">
                                <div className="col-md-6 col-6" style={{paddingRight:"5px"}}>
                                    <button onClick={this.gotoWithdrew.bind(this,"/wallet/withdrew/?coin="+val.coin_symbol)}>Withdraw</button>
                                </div>
                                <div className="col-md-6 col-6" style={{paddingLeft:"5px"}}>
                                    <button data-toggle="modal"  data-target="#exampleModal">Transfer</button>
                                </div>
                            </div>
                            <button onClick={this.onCopyClipboard.bind(this,"https://"+this.state.weburl+"/?ref="+val.ref_code)}
                                style={{marginBottom:"50px"}}>Invite Friends 10% <i className="fas fa-share-alt float-end"></i></button>
                            <p className="infotext">
                                <i className="fas fa-long-arrow-alt-right"></i>  You can earn {val.mining_amount} KocoCoin every 24 hours.
                            </p>
                            <p className="infotext">
                                <i className="fas fa-long-arrow-alt-right"></i> You can transfer a minimum of 40 KocoCoin.  But this is for a limited time.  And you pay a small Bnb charge for verification the first time you transfer to avoid risking multiple accounts.
                                Which will be charged at the time of your first transfer, not later.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="modal fade transfermodal" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Asset transfer</h5>
                                <a className="close" data-dismiss="modal"
                                   aria-label="Close">
                                    <i style={{cursor:"pointer"}}
                                       className="fas fa-times"></i>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="fromto">
                                    <p><i className="fab fa-codepen"></i> From Mining</p>
                                    <p>
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <i className="fas fa-arrow-down"></i>
                                            </div>
                                            <div className="col-md-10 col-9">
                                              <p style={{width:"100%",paddingTop:"10px",
                                                  borderBottom:"2px solid white"}}></p>
                                            </div>
                                            <div className="col-md-1 col-1">
                                                <i style={{fontSize:"20px",paddingRight:"20px"}} className="fas fa-retweet "></i>
                                            </div>
                                        </div>
                                    </p>
                                    <p><i className="fas fa-wallet"></i> To Spot
                                        <i style={{paddingRight:"0px!important",
                                            paddingLeft:"20px"
                                        }}
                                           className="fas fa-caret-down float-end"></i>
                                    </p>
                                </div>
                                <div className="form-group">
                                    <p style={{color:"#2094FF",marginBottom:"4px",fontSize:"18px"}}>Crypto</p>
                                    <div className="crypto">
                                        <img src={val.logo_img?ApiUrl.photoUrl+val.logo_img:"./icon.png"}/> {val.coin_symbol} <i className="fas fa-caret-down float-end"></i>
                                    </div>
                                    <p style={{color:"#2094FF",marginBottom:"4px",fontSize:"18px",marginTop:"10px"}}>Quantity</p>
                                    <div className="crypto" style={{padding:"10px"}}>
                                        <input onChange={this.amount} value={val.amount}
                                               type="number" className="form-control shadow-none mt-1"
                                               id="exampleFormControlInput1" placeholder=""/>
                                        <p onClick={this.all} style={{color:"white",float:"right",
                                            width:"10%",cursor:"pointer",padding:"5px",paddingLeft:"10px",
                                            borderLeft:"1px solid white"}}>All</p>
                                    </div>
                                    <p style={{color:"#2094FF",marginTop:"10px",fontSize:"13px"}}>Balance：{val.balance} {val.coin_symbol}</p>
                                    <button data-dismiss="modal" disabled={val.loading1}
                                            aria-label="Close" onClick={this.transfer}>{val.loading1==true?"Loading...":"Transfer"}</button>
                                </div>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Mining;
