import React,{Component,Fragment} from "react";
import Footer from "../components/Footer";
import TopMenu from "../components/TopMenu";
import Carousel from 'better-react-carousel'
import {Link} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";

class Home extends Component {

    constructor() {
        super();
        this.state={
            token:"",blog:[],coin:[]
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var ref = query.get('ref')
        if(ref){
            sessionStorage.setItem("ref",ref)
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
        }
        var blog = JSON.parse(localStorage.getItem("blog"))
        if(blog){
            this.setState({blog:blog})
        }
        var coin = JSON.parse(localStorage.getItem("coin"))
        if(coin){
            this.setState({coin:coin})
        }
        Axios.get("https://api.coinpaprika.com/v1/tickers/btc-bitcoin")
            .then(res=>{
                //console.log(res.data.quotes["USD"]["price"])
                localStorage.setItem("btc",res.data.quotes["USD"]["price"])
            })
            .catch(err=>{
                //console.log(err)
            })
        this.getBlog()
        this.getCoin()
        this.priceUpdate()
    }

    getBlog=()=>{
        Axios.get(ApiUrl.baseurl+"all-blog")
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    console.log("err")
                }else{
                    if(res.data.length>0){
                        localStorage.setItem("blog",JSON.stringify(res.data))
                        this.setState({blog:res.data})
                    }
                }
            })
            .catch(err=>{
                //console.log(err)
                //this.getBlog()
            })
    }
    getCoin=()=>{
        Axios.get(ApiUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    console.log("err")
                }else{
                    if(res.data.length>0){
                        localStorage.setItem("coin",JSON.stringify(res.data))
                        this.setState({coin:res.data})
                    }
                }
            })
            .catch(err=>{
                //console.log(err)
                //this.getCoin()
            })
    }
    priceUpdate=()=>{
        Axios.get(ApiUrl.baseurl+"coin-price")
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                //console.log(err)
            })
    }

    render() {

        var val = this.state

        var sliderimg = val.blog.map(res=>{
            return(
                <Carousel.Item>
                    <Link to={"/news-blog/"+res.id}>
                        <img width="100%" height="150px" src={ApiUrl.photoUrl+res.img} />
                    </Link>
                </Carousel.Item>
            )
        })

        var coinlist = val.coin.map(res=>{
            return(
                <>
                    <tr>
                        <td className="crypto-name">
                            <div
                                className="coin-info d-flex align-items-center justify-content-center">
                                <div className="coin-logo me-1">
                                    <img src={ApiUrl.photoUrl+res.logo_img} alt={res.coin_symbol}/>
                                </div>
                                <h2 className="coin-name text-left fw-bold fs-5 m-0">{res.coin_symbol}</h2>
                            </div>
                        </td>
                        <td>
                            <div className="last-price text-center">
                                <p className="coin-price fw-bold p-0 m-0">{parseFloat(res.price).toFixed(2)} USD</p>
                            </div>
                        </td>
                        <td>
                            <div className="chage-24 text-center">
                                <p className={parseFloat(res.day_change)>0?"change fw-bold text-primary":
                                    "change fw-bold text-danger"}>{parseFloat(res.day_change)>0?"+ ":" "}{ res.day_change}</p>
                            </div>
                        </td>
                        <td className="popular-chart">
                            <div className="graph">
                                <img src="/chart.jpg" alt=""/>
                            </div>
                        </td>
                        <td className="action">
                            <div
                                className="trading text-center h-100 align-self-center">
                                <a href="#"
                                   className="btn btn-primary shadow-none rounded-3">Buy</a>
                            </div>
                        </td>
                    </tr>

                </>
            )
        })

        return (
            <Fragment>
                <TopMenu title="KocoCoin Exchange Platform"/>
                <section id="hero-section" className="py-5">
                    <div className="container">
                        <div className="row py-5 align-items-center">
                            <div className="col-md-6 ">
                                <Link to="/presale">
                                    <img className="prebtn" src="/buy.png"/>
                                </Link>
                                <h1 className="text-white fw-bold ">Join us in the exciting world of <span
                                    className="text-secondary">Koco Coin</span> and start your cryptocurrency journey
                                    today</h1>
                                <h6 className="fw-bold text-primary">Don't miss out on this limited-timer offer!</h6>
                                <div className="action mt-3">
                                    {
                                        val.token?
                                          <>
                                              <Link to="/mining" className="btn koco-primary me-3 shadow-none">Mining</Link>
                                              <Link to="/wallet" className="btn koco-outline shadow-none">My Wallet</Link>
                                          </>:
                                            <>
                                                <Link to="/register" className="btn koco-primary me-3 shadow-none">Sign Up</Link>
                                                <Link to="/login" className="btn koco-outline shadow-none">Get Reward</Link>
                                            </>
                                    }
                                </div>
                                <div className="row mt-4 d-none">
                                    <div className="col-2">
                                        <h4 className="text-white fs-6 text-left">100k <br/> Coin</h4>
                                    </div>
                                    <div className="col-4 text-left">
                                        <h4 className="text-white fs-6 text-left">100k <br/> Global Investors</h4>
                                    </div>
                                    <div className="col-5 ">
                                        <h4 className="text-white fs-6 text-left">100k <br/> 24h trading text-center
                                            Volume</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="hero-img">
                                    <img src="/trusted.png" alt="Hero Image" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*
<div className="slider">
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                    <div className="slides">
                                        <div className="banner-img p-3">
                                            <img style={{width:"100%",height:"100%"}} src="/slider.jpg"
                                                 alt="Slide 1"/>
                                        </div>
                                    </div>
                                </div>
                */}

                <div className="bg-white" style={{height:"100%",width:"100%"}}>
                    <section id="banner-slider bg-white" className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <p>
                                        <Link to="/news-blog"
                                              style={{marginBottom:"5px",
                                                  textDecoration:"none",
                                                  float:"right",
                                                  paddingRight:"20px"
                                              }}>
                                            View All <i className="fas fa-arrow-right"></i></Link>
                                    </p>

                                </div>
                                <div className="col-md-12">
                                    <Carousel  dotColorActive={"#100e0e"} dotColorInactive={"#ccc"}
                                               cols={4} rows={1} gap={15} loop={true}
                                               scrollSnap={true} showDots={true}>
                                        {sliderimg}

                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="crypto-market" className="py-5 bg-secondary">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="fs-2 text-secondary fw-bold text-center text-primary">Today Crypto
                                        Markets</h2>
                                    <p className="text-center">The table below shows the top crypto in the last 24 hours.
                                        Check them out and buy them according to your needs</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="data-table overflow-auto">
                                        {/*<ul className="nav nav-tabs border-0 mb-3" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="home-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >
                                                    Popular
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="profile-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile"
                                                    aria-selected="false"
                                                >
                                                    Hot Coin
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="messages-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#messages"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="messages"
                                                    aria-selected="false"
                                                >
                                                    Top Gainers
                                                </button>
                                            </li>
                                        </ul>*/}
                                        <br/>
                                        <div className="tab-content bg-white overflow-auto" style={{borderRadius:"15px"}}>
                                            <div className="tab-pane active" id="home" role="tabpanel"
                                                 aria-labelledby="home-tab">
                                                <div className="popular mt-4">
                                                    <table className="table table-bordered border-primary align-middle">
                                                        <thead className="border">
                                                        <tr className="text-center text-primary table-head">
                                                            <th scope="col">Crypto Name</th>
                                                            <th scope="col">Last Price</th>
                                                            <th scope="col">Change 24h</th>
                                                            <th scope="col">Chart</th>
                                                            <th scope="col">Trading</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {coinlist}
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="koco-coins-ervices" className="bg-secondary pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ">
                                    <h2 className="text-center pt-5 pb-6 mb-5 fw-bold text-light">Koco Coin Products &
                                        Services</h2>
                                </div>
                            </div>
                            <div className="row pb-5">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                    <div
                                        className=" d-md-flex d-sm-block d-lg-flex bg-white rounded-10 px-5 py-4 custom-shadow">
                                        <div className="info ">
                                            <h2 className="text-secondary fw-bold rounded-10">Spot trading</h2>
                                            <p className="text-dark fw-bold p-0">KocoCoin offers a spot trading
                                                KocoCoin We will provide the most powerful Trade System.  And we will take the lowest trede free.
                                            </p>
                                        </div>
                                        <div className="feature_img  ">
                                            <img src="/spot.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                    <div
                                        className="d-md-flex d-sm-block d-lg-flex bg-white rounded-10 custom-shadow px-5 py-4">
                                        <div className="info">
                                            <h2 className="text-secondary fw-bold rounded-10">Future Trading </h2>
                                            <p className="text-dark fw-bold p-0">In KocoCoin Futures Trade you will find a powerful system.  Where you can start business and make profit.
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img src="/Roket.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                    <div
                                        className=" d-md-flex d-sm-block d-lg-flex bg-white rounded-10 custom-shadow px-5 py-4">
                                        <div className="info">
                                            <h2 className="text-secondary fw-bold rounded-10">Funding Account</h2>
                                            <p className="text-dark fw-bold p-0">
                                                Deposit your Fund in KocoCoin.  KocoCoin will give you maximum security.
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img src="/funding.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                    <div
                                        className=" d-md-flex d-sm-block d-lg-flex bg-white rounded-10 custom-shadow px-5 py-4">
                                        <div className="info">
                                            <h2 className="text-secondary fw-bold rounded-10">KocoCoin Exchange </h2>
                                            <p className="text-dark fw-bold p-0">
                                                KocoCoin Exchange and Koco Blockchain are very powerful.  Mining yourself and join our Airdrop to Earn KocoCoin.
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img  src="/kocoex.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="why-choose-koco ">
                        <div className="container ">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <h1 className="text-center text-primary mt-5 mb-2 fw-bold text-light">Why Choose KOCO
                                        Exchange </h1>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-12 col-md-4 col-lg-4  mb-3">
                                    <div className="features custom-shadow p-4 feature-round mb-5">
                                        <div className="text-center">
                                            <img src="/Secure.png" alt=""/>
                                            <h4 className="text-center text-primary fw-bold">KocoCoin Exchange</h4>
                                            <p className="text-center text-dark fw-bold">KocoCoin Exchange and Koco Blockchain are very powerful. Mining yourself and join our Airdrop to Earn KocoCoin.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="features custom-shadow p-4  feature-round">
                                        <div className="text-center">
                                            <img src="/secureasset.jpg" alt=""/>
                                            <h4 className="text-center text-primary fw-bold">KocoCoin NFT</h4>
                                            <p className="text-center text-dark fw-bold">KocoCoin has strong Nft market.  Can keep Nft safe in your account.
                                                And you can sell and buy.  We use very powerful technology for Nft.  which we use to protect your assets.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 p-4 ">
                                    <div className="features middle-item custom-shadow  py-sm-0 feature-round">
                                        <div className="text-center p-4">
                                            <img src="/trusted.png" alt=""/>
                                            <h4 className="text-center text-primary fw-bold">Trusted Platform</h4>
                                            <p className="text-center text-dark fw-bold">Any attack and risk control we can do very quickly. Where our team is always aware
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4  mb-3">
                                    <div className="features custom-shadow p-4 feature-round mb-5">
                                        <div className="text-center">
                                            <img src="/stron.jpg" alt=""/>
                                            <h4 className="text-center text-primary fw-bold">Strong Account
                                                Security</h4>
                                            <p className="text-center text-dark fw-bold">We maintain strict security and round-the-clock
                                                monitoring to keep your assets safe.
                                                <br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="features custom-shadow p-4 feature-round">
                                        <div className="text-center">
                                            <img src="/assettranparwncy.jpg" alt=""/>
                                            <h4 className="text-center text-primary fw-bold">KocoCoin Blockchain</h4>
                                            <p className="text-center text-dark fw-bold">We will turn KocoCoin Blockchain into a powerful Coin.  Koco will be a powerful coin.
                                                Which will always run upwards
                                                <br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="star-mining bg-secondary ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <div className="mining-img overflow-hidden">
                                        <img width="100%" src="/kococoin.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="start-earning mb-5">
                                        <h4 className=" text-primary fw-bold pt-5 pb-4">Start Earning koco Coin Here</h4>
                                        <span className="text-dark">Don't miss out on this limited-time offer!</span>
                                        <h6 className="fw-bold text-dark pb-3">View more benefits</h6>
                                        <div className="row">
                                            <div className="col-md-6 col-8">
                                                <Link to="/mining">
                                                    <button className="btn-primary ps-4 pe-4 pt-2 pb-2 mb-3 shadow-none">Start Mining
                                                    </button>
                                                </Link>
                                                <div className="row">
                                                    <div className=" col-sm-12 col-md-10 col-lg-8">
                                                        <div className="logos overflow-hidden d-flex w-100 ms-3 ">
                                                            <img width="100%" src="/apple.svg" alt=""/>
                                                            <a target="_blank" style={{marginTop:"5px"}} href="/app-release.apk">
                                                            <img className="ms-3 me-3" width="100%"
                                                                 src="/google-play.svg" alt=""/>
                                                            </a>
                                                            <img width="100%" src="/windows.svg" alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-4">
                                                <div className="download-apps overflow-hidden">
                                                    <img width="100%" src="/qr-code.png" alt="QR code"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="start-journey bg-white py-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 align-self-center d-block">
                                    <div className="text-center ">
                                        <h6 className="text-center text-primary fs-4 fw-bold mb-3">Start Your Crypto Journey
                                            Now!</h6>
                                        {
                                            this.state.token?"":
                                                <Link to="/register">
                                                    <button className="btn-primary ps-4 pe-4 pt-2 pb-2 shadow-none">Join Today</button>
                                                </Link>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="text-center journey-img overflow-hidden">
                                        <img src="/journey.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="our-community py-5 bg-secondary">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="text-center text-primary fw-bold">Join our Community</h2>
                                    <p className="text-center text-dark">
                                        KocoCoin Exchange and Koco Blockchain are very powerful.<br/> Mining yourself and join our Airdrop to Earn KocoCoin.</p>
                                    <div className="d-flex text-center justify-content-center logoimgmining">
                                        <img className="ms-3 me-3" src="/globe-solid.svg" alt=""/>
                                        {/*<img className="ms-3 me-3" src="/caret-up-solid.svg" alt=""/>*/}
                                            <img className="ms-3 me-3" src="/google-play.svg" alt=""/>
                                        <img className="ms-3 me-3" src="/apple.svg" alt=""/>
                                    </div>
                                    <div className="action mt-3 text-center">
                                        <button className="btn-primary shadow-none ps-5 pe-4 pt-2 pb-2 me-3 d-flex justify-content-start align-self-center">
                                            <a href="https://twitter.com/KocoCoinNews" style={{textDecoration:"none",color:"white"}} target="_blank">
                                                <img className="me-2" width="20px" height="20px" src="/x-twitter.svg"
                                                     alt=""/>Twitter
                                            </a>
                                        </button>
                                        <button className="btn-primary shadow-none ps-5 pe-4 pt-2 pb-2 me-3 d-flex justify-content-start align-self-center">
                                            <a href="https://t.me/KocoCoinExchangeNews" style={{textDecoration:"none",color:"white"}} target="_blank">
                                                <img className="me-2" width="20px" height="20px" src="/telegram.svg"
                                                     alt=""/>Telegram
                                            </a>
                                        </button>
                                        <button className="btn-primary shadow-none ps-4 pe-4 pt-2 pb-2">
                                            <a href="https://t.me/KocoCoinNews" style={{textDecoration:"none",color:"white"}} target="_blank">
                                                <img className="me-2" width="20px" height="20px" src="/telegram.svg"
                                                     alt=""/>Telegram
                                            </a>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq  bg-white" style={{paddingBottom:"50px",paddingTop:"50px"}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 overflow-hidden">
                                    <div className="faq-icon overflow-hidden">
                                        <img width="100%" height="100%" src="/FAQ.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="faq">
                                        <h2 className="fw-bold fs-2 text-primary mb-3">FAQ</h2>
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button fw-bold shadow-none" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne">What is KocoCoin?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse "
                                                         aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            KocoCoin is a digital currency capable of very fast transactions.  Users can send and receive Kococoin directly without intermediaries, making it a fast and low-cost way to transfer value.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button fw-bold shadow-none" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                aria-expanded="true"
                                                                aria-controls="collapseTwo">Is KocoCoin a safe
                                                            cryptocurrency Exhcnage?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            KocoCoin is committed to providing a safe and reliable trading platform and uses the world's most sophisticated security technology, including a dedicated security team that works continuously to keep your assets and accounts safe, and KocoCoin provides the best service to every user.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button fw-bold shadow-none" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                                aria-expanded="true"
                                                                aria-controls="collapseThree">What is the lowest amount I can start trading on KocoCoin?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse"
                                                         aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            You can start trading on KocoCoin with just $1.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer/>
            </Fragment>
          );
    }
}


export default Home;
